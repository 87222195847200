<template>
  <main-container class="mt-12">
    <page-sizer>
      <v-row>
        <v-col v-for="{ id, title } in coverImages" :key="id" cols="4">
          <v-hover #default="{hover}">
            <v-img
              :src="`/api/book-cover/${id}`"
              max-height="50vh"
              @click="showDetails = { visible: true, id }"
              :class="{ 'on-hover': hover }"
              class="cover"
              contain
            >
              <template #placeholder>
                <v-container fill-height>
                  <v-row>
                    <v-col></v-col>
                    <v-col>
                      <v-progress-circular
                        indeterminate
                        :size="$vuetify.breakpoint.width * 0.1"
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </template>
            </v-img>
          </v-hover>
          <div v-html="title"></div>
        </v-col>
      </v-row>
      <v-dialog v-model="showDetails.visible">
        <show-book-details
          @close-dialog="closeDialog"
          :id="showDetails.id"
        ></show-book-details>
      </v-dialog>
    </page-sizer>
  </main-container>
</template>

<script>
import { mapGetters } from "vuex";
import ShowBookDetails from "../components/Home/ShowBookDetails.vue";
import MainContainer from "../components/MainContainer.vue";
import PageSizer from "../components/PageSizer.vue";
export default {
  name: "Home",
  components: { ShowBookDetails, MainContainer, PageSizer },
  data: () => ({
    showDetails: {
      visible: false,
      id: "",
    },
  }),
  computed: {
    ...mapGetters(["coverList"]),
    coverImages() {
      return this.coverList.map(({ _id: id, title }) => ({
        id,
        title,
      }));
    },
  },
  methods: {
    closeDialog() {
      this.showDetails.visible = false;
    },
  },
};
</script>

<style>
.cover {
  cursor: pointer;
}

.cover.on-hover {
  border: 3px solid black;
}
</style>
