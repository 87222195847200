<template>
  <v-card>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-icon @click="$emit('close-dialog')" class="ml-12">
        mdi-close-circle
      </v-icon>
    </v-card-actions>
    <v-card-title v-html="details.title"></v-card-title>
    <v-container>
      <v-row>
        <v-col sm="6">
          <v-img :src="`/api/book-cover/${details._id}`"></v-img>
        </v-col>
        <v-col>
          <v-card-text class="text-left">
            {{ details.description }}
          </v-card-text>
          <v-card-text>
            <a :href="details.purchaseURL" target="_blank">
              Purchase on Amazon
            </a>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["id"],
  computed: {
    ...mapGetters(["coverList"]),
    details() {
      return this.coverList.filter(({ _id }) => _id == this.id).shift();
    },
    purchaseLink() {
      return this.details.purchaseURL.indexOf("amazon.com") > -1
        ? "mdi-amazon"
        : "mdi-link-variant";
    },
  },
};
</script>

<style>
a.purchase-link {
  text-decoration: none;
}
</style>
